import React from 'react'

export const IconClose = props => {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        fill="none"
        fill-rule="evenodd"
        stroke="#000"
        stroke-linecap="square"
        stroke-width="2"
        transform="translate(1 1)"
      >
        <path d="m.5.5 13.1210938 13.1210938" />
        <path
          d="m.5.5 13.1210938 13.1210938"
          transform="matrix(-1 0 0 1 14 0)"
        />
      </g>
    </svg>
  )
}
