import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { Marker } from 'react-google-maps'
import {
  compose,
  entries,
  eq,
  find,
  get,
  groupBy,
  map,
  propEq,
} from 'lodash/fp'
import { nodes, getUnlessEmpty } from 'helpers'

import { Box, Flex, SVG } from 'system'
import { BoundedBox } from 'src/components/BoundedBox'
import { ContactInfo } from 'src/components/ContactInfo'
import { GoogleMap } from 'src/components/GoogleMap'
import { Layout } from 'src/components/Layout'
import { MarkdownContent } from 'src/components/MarkdownContent'
import { Physician } from 'src/components/Physician'
import { Subheading } from 'src/components/Subheading'
import { IconClose } from 'src/components/IconClose'

import { PageTitle } from 'src/slices/PageTitle'

const LocationsGroup = props => (
  <Box
    borderTop={2}
    borderColor="yellow"
    pt={2}
    mb={[4, 6]}
    boxStyle="lastNoMargin"
    {...props}
  />
)

const Location = ({
  isActive = false,
  name,
  addressMarkdown,
  phoneNumber,
  faxNumber,
  ...props
}) => (
  <Flex
    role="button"
    tabindex={0}
    aria-pressed={isActive}
    bg={isActive ? 'sandExtraLight' : 'transparent'}
    border={2}
    borderColor="sandExtraLight"
    boxStyle="everySecondNoMargin"
    cursor="pointer"
    flexDirection="column"
    mb={[3, 4, 0]}
    mr={[null, null, 4]}
    p={3}
    width={[1, null, 1 / 2]}
    css={`
      transition: background-color ${p => p.theme.transition};
    `}
    {...props}
  >
    <Subheading mb={1} color="navy">
      {name}
    </Subheading>
    <MarkdownContent
      markdown={addressMarkdown}
      lineHeight="copy"
      mb={2}
      flex="1 1 auto"
    />
    <ContactInfo type="phone" withIcon={false} mb={2}>
      {phoneNumber}
    </ContactInfo>
    <ContactInfo type="fax" withIcon={false}>
      {faxNumber}
    </ContactInfo>
  </Flex>
)

const defaultMapSettings = {
  lat: 20.60965617233257,
  lng: -157.71473596309283,
  zoom: 7,
}

const LocationsPage = ({ data }) => {
  const [lat, setLat] = useState(defaultMapSettings.lat)
  const [lng, setLng] = useState(defaultMapSettings.lng)
  const [zoom, setZoom] = useState(defaultMapSettings.zoom)
  const [activeLocationId, setActiveLocationId] = useState(null)
  const [physiciansIsOpen, setPhysiciansIsOpen] = useState(false)

  const toggleActiveLocation = location => {
    const resetting = eq(activeLocationId, get('id', location))

    setActiveLocationId(resetting ? null : get('id', location))
    setLat(
      resetting
        ? defaultMapSettings.lat
        : get('frontmatter.mapCoordinates.lat', location)
    )
    setLng(
      resetting
        ? defaultMapSettings.lng
        : get('frontmatter.mapCoordinates.lng', location)
    )
    setZoom(resetting ? defaultMapSettings.zoom : 15)
  }

  const togglePhysiciansIsOpen = () => setPhysiciansIsOpen(!physiciansIsOpen)

  const locations = compose(
    nodes,
    get('locations')
  )(data)
  const locationsByIsland = compose(
    entries,
    groupBy('frontmatter.island')
  )(locations)
  const physicians = compose(
    nodes,
    get('physicians')
  )(data)

  const activeLocation = find(propEq('id', activeLocationId), locations)
  const activeLocationPhysicians = compose(
    map(x =>
      find(propEq('frontmatter.path', get('physicianRelation', x)), physicians)
    ),
    get('frontmatter.relatedPhysicians')
  )(activeLocation)

  const googleMapsAPIKey = process.env.GATSBY_GOOGLE_API_KEY

  const page = get('file.childMarkdownRemark', data)

  return (
    <Layout>
      <Helmet>
        <title>{get('frontmatter.title', page)}</title>
      </Helmet>
      <PageTitle>{get('frontmatter.title', page)}</PageTitle>
      <BoundedBox pt={[0, 10]} pl={[0, 4]} pr={[0, 4]} pb={0}>
        <GoogleMap
          center={{ lat, lng }}
          containerElement={<Box height="25rem" />}
          defaultCenter={{ lat: 20.59993217456075, lng: -157.439479984375 }}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapsAPIKey}`}
          loadingElement={<Box height="25rem" />}
          mapElement={<Box height="100%" />}
          zoom={zoom}
        >
          {map(
            location => (
              <Marker
                key={get('id', location)}
                position={get('frontmatter.mapCoordinates', location)}
                onClick={() => toggleActiveLocation(location)}
              />
            ),
            locations
          )}
        </GoogleMap>
      </BoundedBox>
      <BoundedBox>
        <Flex flexDirection={['column', 'row']}>
          <Box width={[1, 1 / 2, 8 / 12]} mr={[null, 4]}>
            {getUnlessEmpty('rawMarkdownBody', page) && (
              <LocationsGroup>
                <MarkdownContent markdown={get('rawMarkdownBody', page)} />
              </LocationsGroup>
            )}
            {map(
              ([island, locations]) => (
                <LocationsGroup>
                  <Subheading mb={2}>{island}</Subheading>
                  <Flex as="ul" flexDirection={['column', null, 'row']}>
                    {map(
                      location => (
                        <Location
                          key={get('id', location)}
                          isActive={eq(activeLocationId, get('id', location))}
                          name={get('frontmatter.title', location)}
                          addressMarkdown={get('frontmatter.address', location)}
                          phoneNumber={get('frontmatter.phoneNumber', location)}
                          faxNumber={get('frontmatter.faxNumber', location)}
                          onClick={() => toggleActiveLocation(location)}
                          onKeyPress={() => toggleActiveLocation(location)}
                        />
                      ),
                      locations
                    )}
                  </Flex>
                </LocationsGroup>
              ),
              locationsByIsland
            )}
          </Box>
          <Box
            width={[1, 1 / 2, 4 / 12]}
            bg="sandExtraLight"
            p={[3, 4]}
            position={['fixed', 'static']}
            top={physiciansIsOpen ? 0 : 'calc(100% - 5.25rem)'}
            bottom={0}
            left={0}
            right={0}
            zIndex={2}
            overflow="scroll"
            css={`
              -webkit-overflow-scrolling: touch;
              transition: top ${p => p.theme.transition};
            `}
          >
            <Box
              cursor="pointer"
              onClick={togglePhysiciansIsOpen}
              onKeyPress={togglePhysiciansIsOpen}
              role="button"
              tabindex={0}
              p={[3, 4]}
              m={[-3, -4]}
              position="relative"
            >
              <SVG
                svg={IconClose}
                x={1}
                y={1}
                fill="navy"
                width="1rem"
                position="absolute"
                top="2rem"
                right="1.25rem"
                opacity={physiciansIsOpen ? 1 : 0}
                css={`
                  transition: opacity ${p => p.theme.transition};
                `}
              />
              <Subheading color="navy" fontSize="medium" textAlign="center">
                View Physicians by Location
              </Subheading>
              <Subheading fontSize={['small', 'medium']} textAlign="center">
                {activeLocation
                  ? get('frontmatter.title', activeLocation)
                  : 'Select a Location'}
              </Subheading>
            </Box>
            <Flex
              as="ul"
              flexWrap="wrap"
              justifyContent="center"
              mb={2}
              mt={3}
              display={[physiciansIsOpen ? 'flex' : 'none', 'flex']}
            >
              {map(
                physician => (
                  <Physician
                    key={get('id', physician)}
                    as="li"
                    imageFluid={get(
                      'frontmatter.photo.childImageSharp.fluid',
                      physician
                    )}
                    name={get('frontmatter.title', physician)}
                    path={get('frontmatter.path', physician)}
                    width={[1 / 2, 1 / 2]}
                    mb={[2, 4]}
                    px={2}
                  />
                ),
                activeLocation ? activeLocationPhysicians : physicians
              )}
            </Flex>
          </Box>
        </Flex>
      </BoundedBox>
    </Layout>
  )
}

export default LocationsPage

export const query = graphql`
  query LocationsPage {
    file(relativePath: { eq: "customPages/locations.md" }) {
      childMarkdownRemark {
        frontmatter {
          title
        }
        rawMarkdownBody
      }
    }
    locations: allMarkdownRemark(
      sort: { fields: [frontmatter___order] }
      filter: { fileAbsolutePath: { regex: "/(locations)/.*.md$/" } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            order
            address
            phoneNumber: phone_number
            faxNumber: fax_number
            hours
            island
            mapCoordinates: map_coordinates {
              lat: latitude
              lng: longitude
            }
            relatedPhysicians: related_physicians {
              physicianRelation: physician_relation
            }
          }
        }
      }
    }
    physicians: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(physicians)/.*.md$/" } }
    ) {
      edges {
        node {
          frontmatter {
            path
            title
            photo {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
